import React from 'react';
import { ChakraProvider, VStack, theme, Stack } from '@chakra-ui/react';
import BodyBottom from './BodyBottom';
import Header from './Header';
import BodyTop from './BodyTop';
import Footer from './Footer';

function App() {
  return (
    <ChakraProvider theme={theme}>
      <VStack bg="gray.100">
        <Stack alignItems={'center'} mx={10} my={4}>
          <Header />
          <BodyTop />
          <BodyBottom />
          <Footer />
        </Stack>
      </VStack>
    </ChakraProvider>
  );
}

export default App;
