import React from 'react';
import { Box, Text, Image, Stack, Flex } from '@chakra-ui/react';
import Icon from './icon.png';

const Header = () => {
  return (
    <>
      <Stack direction={{ base: 'column', lg: 'row' }} w="full" pb={10}>
        <Box w={{ base: '100%', lg: '50%' }}>
          <Image src={Icon} display="inline" />
          <Text
            display="inline"
            color="blue.400"
            fontWeight={'bold'}
            fontSize="5xl"
          >
            YENI ROTA
          </Text>
        </Box>
        <Flex
          w={{ base: '100%', lg: '50%' }}
          alignSelf={'flex-end'}
          justifyContent={'end'}
        >
          <Text fontWeight={'semibold'} color="gray.600" fontSize="2xl">
            Welcome to Yeni Rota Ltd.
          </Text>
        </Flex>
      </Stack>
      {/* DESC */}
      <Box
        textAlign="center"
        w={{ base: '100%', lg: '75%' }}
        borderWidth={1}
        shadow="md"
        borderRadius={12}
      >
        <Text p={5} fontWeight="semibold" color="gray.600" fontSize="lg">
          We partner with businesses of all sizes that we believe we can add
          real value to. This usually means you’re an established business that
          ships products regularly. If this sounds like you, get in touch. We
          can also help you with food supplies, construction materials and
          chemical products.
        </Text>
      </Box>
    </>
  );
};

export default Header;
